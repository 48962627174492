import React from "react";
import { ReactComponent as IconOne } from "../../asset/img/icon/Icon_1_l.svg";
import { ReactComponent as IconTwo } from "../../asset/img/icon/Icon_2_l.svg";
import { ReactComponent as IconThree } from "../../asset/img/icon/Icon_3_l.svg";
import { ReactComponent as IconFour } from "../../asset/img/icon/Icon_4_l.svg";
import { ReactComponent as IconFive } from "../../asset/img/icon/Icon_5_l.svg";
import { ReactComponent as IconSix } from "../../asset/img/icon/Icon_6_l.svg";

const CoreValue = () => {
	return (
		<div className="core-value">
			<div className="core-value__main">
				<p className="core-value__main--intro">
					<span>技術整合</span>
					<span>系統建置</span>
					<span>一次到位</span>
				</p>
				<p className="core-value__main--desc">
					能夠配合多種數位系統與金流需求，進行開發技術整合或是串接服務。
					提供客戶完整的前台／後台系統客製化架構，因應多樣化需求的後台模組化系統，及提供前端行銷策略規劃服務的整合式優化。
				</p>
			</div>
			<ul className="core-value__list">
				<li className="core-value__item">
					<IconOne className="core-value__icon" />
					<span className="core-value__text">產品開發</span>
				</li>
				<li className="core-value__item">
					<IconTwo className="core-value__icon" />
					<span className="core-value__text">數位系統整合</span>
				</li>
				<li className="core-value__item">
					<IconThree className="core-value__icon" />
					<span className="core-value__text">金流整合</span>
				</li>
				<li className="core-value__item">
					<IconFour className="core-value__icon" />
					<span className="core-value__text">系統與產品維運</span>
				</li>
				<li className="core-value__item">
					<IconFive className="core-value__icon" />
					<span className="core-value__text">營運行銷</span>
				</li>
				<li className="core-value__item">
					<IconSix className="core-value__icon" />
					<span className="core-value__text">AI 大數據分析</span>
				</li>
			</ul>
		</div>
	);
};

export default CoreValue;
