import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const FeatureTriangle = ({ xCood, yCood }) => {
	const [yStore, setYStore] = useState(yCood);

	useEffect(() => {
		if (yStore !== yCood) {
			setYStore(yCood);
		}

		//eslint-disable-next-line
	}, [xCood, yCood]);

	if (yCood !== yStore) return null;

	const style =
		yCood === yStore ? { transition: "left 0.3s" } : { transition: "top 0.3s" };

	return (
		<div
			style={{ ...style, top: yCood, left: xCood }}
			className="feature__triangle"
		></div>
	);
};

FeatureTriangle.propTypes = {
	xCood: PropTypes.number,
	yCood: PropTypes.number,
};

export default FeatureTriangle;
