import React from "react";
import PropTypes from "prop-types";

const FeatureContent = ({ contentData, row, activeFeature }) => {
	if (!activeFeature) return null;

	return (
		<div
			className={`feature__content ${
				contentData[activeFeature].row === row ? "active" : ""
			}`}
		>
			{contentData[activeFeature].row === row &&
				contentData[activeFeature].content.map((content, index) => (
					<div
						key={index}
						className={`feature__content--text feature__content--text--${
							index + 1
						}`}
					>
						{content.split("\n").map((text, index) => {
							return (
								<React.Fragment key={index}>
									{text}
									<br />
								</React.Fragment>
							);
						})}
					</div>
				))}
		</div>
	);
};

FeatureContent.propTypes = {
	contentData: PropTypes.objectOf(PropTypes.object),
	row: PropTypes.number,
	activeFeature: PropTypes.string,
};

export default FeatureContent;
