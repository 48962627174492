import React, { useState } from "react";
import "./Navibar.scss";
import { ReactComponent as Hamb } from "../../asset/img/icon/hamburger.svg";

const NavibarApp = () => {
	const [show, setShow] = useState(false);
	const [activeLink, setActiveLink] = useState();

	return (
		<nav className={`navibar ${show ? "show" : ""}`}>
			<button
				onClick={() => setShow((prev) => !prev)}
				className="navibar__button"
			>
				<Hamb className="navibar__button--icon" />
			</button>
			<img
				className="navibar__logo"
				src={require("../../asset/img/logo/RH_logo_l.png")}
				srcSet={`${require("../../asset/img/logo/RH_logo_l.png")} 1x, ${require("../../asset/img/logo/RH_logo_l@2x.png")} 2x`}
				alt="Logo"
			/>
			<ul className={`navibar__list`}>
				<li className="navibar__item">
					<a
						onClick={(e) => {
							setActiveLink(e.target.id);
							setShow(false);
						}}
						id="feature__link"
						className={`navibar__link ${
							activeLink === "feature__link" ? "active" : ""
						}`}
						href="#feature"
					>
						產品
					</a>
				</li>
				<li className="navibar__item">
					<a
						onClick={(e) => {
							setActiveLink(e.target.id);
							setShow(false);
						}}
						id="usecase__link"
						className={`navibar__link ${
							activeLink === "usecase__link" ? "active" : ""
						}`}
						href="#usecase"
					>
						方案
					</a>
				</li>
				<li className="navibar__item">
					<a
						onClick={(e) => {
							setActiveLink(e.target.id);
							setShow(false);
						}}
						id="partners__link"
						className={`navibar__link ${
							activeLink === "partners__link" ? "active" : ""
						}`}
						href="#partners"
					>
						合作夥伴
					</a>
				</li>
				<li className="navibar__item navibar__item--last">
					<a
						onClick={(e) => {
							setActiveLink(e.target.id);
							setShow(false);
						}}
						id="aboutus__link"
						className={`navibar__link ${
							activeLink === "aboutus__link" ? "active" : ""
						}`}
						href="#aboutus"
					>
						關於仁亨
					</a>
				</li>
				<li className="navibar__item">
					<a
						className="apply-btn"
						href="https://www.google.com"
						target="_blank"
						rel="noopener noreferrer"
					>
						立即申請試用
					</a>
				</li>
			</ul>
		</nav>
	);
};

export default NavibarApp;
