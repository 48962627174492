import React, { useEffect, useContext, useState, useRef } from "react";
import { CarouselContext } from "./context/CarouselContext";

import { animated } from "react-spring";
import { useGesture } from "react-with-gesture";

import CarouselDot from "./CarouselDot";
import { ReactComponent as ArrowLeft } from "../../asset/img/icon/Arrow_left.svg";
import { ReactComponent as ArrowRight } from "../../asset/img/icon/Arrow_right.svg";
import { usePreventScroll } from "./usePreventScroll";
import useInterval from "../../hooks/useInterval";

const Carousel = ({ children }) => {
  const {
    state: {
      childrenGroups,
      width,
      height,
      currentIndex,
      isAnimated,
      windowWidth,
    },
    initChildrenGroups,
    onImgLoad,
    onPrevClick,
    onNextClick,
    setCurrentIndex,
    setAnimated,
    setWindowWidth,
  } = useContext(CarouselContext);

  const [gestureOffset, setGestureOffset] = useState(0);
  const [orientation, setOrientation] = useState(false);
  const [delay, setDelay] = useState(10000);

  const preventScrollRef = useRef(false);
  usePreventScroll(preventScrollRef);

  const imgEl = useRef(null);

  const [bind, { delta, down }] = useGesture({
    onDrag: ({ down }) => {
      if (down) {
        preventScrollRef.current = true;
      } else {
        preventScrollRef.current = false;
      }
    },
  });
  // const { x } = useSpring({
  // 	x: springXHandler(delta, down, width),
  // });

  useInterval(delay, () => setCurrentIndex(currentIndex + 1));

  useEffect(() => {
    const initWindowWidth = () => {
      setWindowWidth(window.innerWidth);
      setTimeout(() => setOrientation(true), 1);
    };

    window.addEventListener("resize", initWindowWidth);

    return () => {
      window.removeEventListener("resize", initWindowWidth);
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!orientation) return;
    imgEl.current.focus();
    onImgLoad(imgEl.current);
    setOrientation(false);
    //eslint-disable-next-line
  }, [orientation]);

  useEffect(() => {
    const direction = delta[0] > 0 ? 1 : -1;
    if (!down) {
      if (delta[0] * direction > width / 3) {
        const index = currentIndex - direction;
        setCurrentIndex(index);
        setGestureOffset(0);
      } else {
        setGestureOffset(0);
        return;
      }
    } else {
      setGestureOffset(delta[0]);
    }
    //eslint-disable-next-line
  }, [delta, down]);

  useEffect(() => {
    initChildrenGroups(children);
    //eslint-disable-next-line
  }, [children]);

  useEffect(() => {
    if (
      currentIndex > childrenGroups.length - 1 ||
      currentIndex < -childrenGroups.length + 1
    ) {
      setCurrentIndex(0);
    }
    //eslint-disable-next-line
  }, [currentIndex]);

  const styleHandler = (index) => {
    const leftXPosition = -width * 2;
    const rigthXPosition = width * (childrenGroups.length - 3);
    const indexGap = index - currentIndex - 2;
    let xOffset = indexGap * width;

    if (xOffset < leftXPosition) {
      xOffset = (indexGap + 3) * width + rigthXPosition;
    } else if (xOffset > rigthXPosition) {
      xOffset = (indexGap - (children.length + 1)) * width + leftXPosition;
    }

    const opacity =
      xOffset === 0 || xOffset === width || xOffset === -width ? 1 : 0;
    const visibility = opacity === 0 ? "hidden" : "visible";
    const zIndex = opacity === 0 ? 1 : 2;

    return {
      style: {
        xOffset,
        // transform: `translate3d(${xOffset}px, 0, 0)`,
        transition: "all, 0.5s ease",
        opacity,
        visibility,
        zIndex,
      },
      xOffset,
    };
  };

  if (childrenGroups.length === 0) return null;

  return (
    <div onScroll={(e) => e.preventDefault()} className="carousel">
      <div className="carousel__container">
        <animated.ul
          {...bind()}
          ref={preventScrollRef}
          onLoad={(e) => {
            if (width === 0) onImgLoad(e.target);
          }}
          style={{
            width,
            height,
          }}
          className="carousel__group"
        >
          {childrenGroups.map((child, index) => {
            const {
              style: { xOffset, transition, opacity, visibility, zIndex },
            } = styleHandler(index);
            const ref = index === 0 ? imgEl : null;

            return (
              <animated.li
                onTransitionEnd={() => {
                  if (index === 0) {
                    if (isAnimated) setAnimated(false);
                  }
                }}
                onMouseEnter={() => setDelay(null)}
                onMouseLeave={() => setDelay(10000)}
                onTouchStart={() => setDelay(null)}
                onTouchEnd={() => setDelay(10000)}
                key={index}
                className="carousel__group--item"
                style={{
                  transform: `translate3d(${xOffset + gestureOffset}px, 0, 0)`,
                  transition,
                  opacity,
                  visibility,
                  zIndex,
                }}
              >
                {React.cloneElement(child, {
                  key: index,
                  ref,
                })}
              </animated.li>
            );
          })}
        </animated.ul>
        <button
          onClick={() => {
            if (isAnimated) return null;
            onPrevClick();
          }}
          className="carousel__prevbtn"
        >
          <ArrowLeft className="carousel__icon carousel__icon--left" />
        </button>
        <button
          onClick={() => {
            if (isAnimated) return null;
            onNextClick();
          }}
          className="carousel__nextbtn"
        >
          <ArrowRight className="carousel__icon carousel__icon--right" />
        </button>
        <div
          style={{ height, width: (windowWidth - width) / 2 }}
          className="carousel__cover carousel__cover--left"
        />
        <div
          style={{ height, width: (windowWidth - width) / 2 }}
          className="carousel__cover carousel__cover--right"
        />
      </div>
      <CarouselDot
        setCurrentIndex={setCurrentIndex}
        currentIndex={currentIndex}
        dotCount={children.length}
      />
    </div>
  );
};

export default Carousel;

// const springXHandler = (delta, down, width) => {
// 	const direction = delta[0] > 0 ? 1 : -1;
// 	if (!down) {
// 		if (delta[0] * direction > width / 2) {
// 			return width * direction;
// 		} else {
// 			return 0;
// 		}
// 	} else {
// 		return delta[0];
// 	}
// };
