import React from "react";
import "./UseCase.scss";

const UseCaseApp = () => {
	return (
		<section id="usecase" className="usecase">
			<h2 className="primary__heading">產品應用案例</h2>
			<div className="usecase__section">
				<div className="usecase__box">
					<img
						className="usecase__img usecase__img--left"
						src={require("../../asset/img/materials/Img_game_web.png")}
						srcSet={`${require("../../asset/img/materials/Img_game_web.png")} 1x, ${require("../../asset/img/materials/Img_game_web@2x.png")} 2x`}
						alt="Use Case 1"
					/>
					<div className="usecase__instruction">
						<div className="usecase__heading">遊戲娛樂平台</div>
						<ul className="usecase__list">
							<li className="usecase__item">
								<div>
									輪帶表遊戲機率功能，依據玩家等級，增加遊戲困難度及豐富性
								</div>
							</li>
							<li className="usecase__item">
								<div>
									轉蛋，寶箱，卡牌，道具以及抽獎機率功能，企業保證固定機率
								</div>
							</li>
							<li className="usecase__item">
								<div>企業精彩刺激的機率遊戲</div>
							</li>
						</ul>
					</div>
				</div>
				<div className="usecase__box">
					<div className="usecase__instruction">
						<div className="usecase__heading">電子商務平台</div>
						<ul className="usecase__list">
							<li className="usecase__item">
								<div>電子商城框架</div>
							</li>
							<li className="usecase__item">
								<div>
									雲端處理購物車，購物清單，串接第三方金流並開立電子發票
								</div>
							</li>
							<li className="usecase__item">
								<div>電商物流追蹤系統，快速，準確，省錢！</div>
							</li>
						</ul>
					</div>
					<img
						className="usecase__img usecase__img--right"
						src={require("../../asset/img/materials/Img_platform_web.png")}
						srcSet={`${require("../../asset/img/materials/Img_platform_web.png")} 1x, ${require("../../asset/img/materials/Img_platform_web@2x.png")} 2x`}
						alt="Use Case 1"
					/>
				</div>
				<div className="usecase__box">
					<img
						className="usecase__img usecase__img--left"
						src={require("../../asset/img/materials/Img_business_web.png")}
						srcSet={`${require("../../asset/img/materials/Img_business_web.png")} 1x, ${require("../../asset/img/materials/Img_business_web@2x.png")} 2x`}
						alt="Use Case 1"
					/>
					<div className="usecase__instruction">
						<div className="usecase__heading">企業應用優化</div>
						<ul className="usecase__list">
							<li className="usecase__item">
								<div>會員快速註冊/登入</div>
							</li>
							<li className="usecase__item">
								<div>企業雲端訊息服務，大量儲存</div>
							</li>
							<li className="usecase__item">
								<div>企業通信，通訊系統</div>
							</li>
						</ul>
					</div>
				</div>
				<div className="usecase__box">
					<div className="usecase__instruction">
						<div className="usecase__heading">新金融科技</div>
						<ul className="usecase__list">
							<li className="usecase__item">
								<div>數位貨幣錢包</div>
							</li>
							<li className="usecase__item">
								<div>區塊鏈技術與平台整合系統構建</div>
							</li>
							<li className="usecase__item">
								<div>智能合約發幣</div>
							</li>
							<li className="usecase__item">
								<div>溯源資訊安全及認證</div>
							</li>
							<li className="usecase__item">
								<div>多種數位貨幣及金流需求，出/入幣服務</div>
							</li>
						</ul>
					</div>
					<img
						className="usecase__img usecase__img--right"
						src={require("../../asset/img/materials/Img_fintech_web.png")}
						srcSet={`${require("../../asset/img/materials/Img_fintech_web.png")} 1x, ${require("../../asset/img/materials/Img_fintech_web@2x.png")} 2x`}
						alt="Use Case 1"
					/>
				</div>
				<div className="usecase__box">
					<img
						className="usecase__img usecase__img--left"
						src={require("../../asset/img/materials/Img_edu_web.png")}
						srcSet={`${require("../../asset/img/materials/Img_edu_web.png")} 1x, ${require("../../asset/img/materials/Img_edu_web@2x.png")} 2x`}
						alt="Use Case 1"
					/>
					<div className="usecase__instruction">
						<div className="usecase__heading">學校單位學習</div>
						<ul className="usecase__list">
							<li className="usecase__item">
								<div>教學經驗豐富的專業顧問團隊</div>
							</li>
							<li className="usecase__item">
								<div>跨足產學，與東泰高中共同培育產業科技技術前鋒</div>
							</li>
							<li className="usecase__item">
								<div>與翰林出版社共同編輯“ 趣玩Scratch ”中學程式語言教材</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</section>
	);
};

export default UseCaseApp;
