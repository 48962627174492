import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

const AboutUsButton = ({ selectButton, setSelectButton }) => {
  const el = useRef(null);
  useEffect(() => {
    el.current.focus();
    if (selectButton.length === 0) {
      const id = el.current.childNodes[0].id;
      setSelectButton(id);
    }
    //eslint-disable-next-line
  }, []);

  const style = () => {
    if (el.current === null) return;
    el.current.focus();
    const btnAry = el.current.childNodes;
    const btnCount = btnAry.length;
    let selectedIndex = 0;
    btnAry.forEach((btn, index) => {
      if (btn.id === selectButton) selectedIndex = index;
    });

    const offSet = (100 / btnCount) * selectedIndex;

    return { left: `${offSet}%` };
  };

  return (
    <div className="au">
      <div className="au__bg">
        <div style={style()} className="au__bg--area" />
      </div>
      <div ref={el} className="au__btn--container">
        <button
          onClick={(e) => setSelectButton(e.target.id)}
          id="core"
          className={`au__btn ${selectButton === "core" ? "active" : ""}`}
        >
          核心價值
        </button>
        <button
          onClick={(e) => setSelectButton(e.target.id)}
          id="teams"
          className={`au__btn ${selectButton === "teams" ? "active" : ""}`}
        >
          專業團隊
        </button>
      </div>
    </div>
  );
};

AboutUsButton.propTypes = {
  selectButton: PropTypes.string,
  setSelectButton: PropTypes.func,
};

export default AboutUsButton;
