import React, { useReducer, useMemo } from "react";

export default (reducer, actions, defaultValue) => {
	const Context = React.createContext();

	const Provider = ({ children }) => {
		const [state, dispatch] = useReducer(reducer, defaultValue);

		const boundActions = useMemo(() => {
			const _boundActions = {};

			Object.keys(actions).forEach((key) => {
				_boundActions[key] = ((_action, _dispatch) => {
					return (...argument) => _action(...argument)(_dispatch);
				})(actions[key], dispatch);
			});

			return _boundActions;
		}, []);

		return (
			<Context.Provider value={{ state, ...boundActions }}>
				{children}
			</Context.Provider>
		);
	};

	return { Context, Provider };
};
