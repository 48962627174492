import React from "react";
import PropTypes from "prop-types";

const CarouselDot = ({ dotCount, currentIndex, setCurrentIndex }) => {
	return (
		<ul className="dot">
			{[...Array(dotCount)].map((_, index) => {
				const active =
					Math.abs(currentIndex + dotCount * 2) % dotCount === index;
				return (
					<li
						onClick={() => setCurrentIndex(index)}
						key={index}
						className={`dot__dot ${active ? "active" : ""}`}
					/>
				);
			})}
		</ul>
	);
};

CarouselDot.propTypes = {
	dotCount: PropTypes.number,
	currentIndex: PropTypes.number,
	setCurrentIndex: PropTypes.func,
};

export default CarouselDot;
