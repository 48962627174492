import React from "react";
import "./Partners.scss";

const PartnersApp = () => {
	return (
		<section id="partners" className="partners">
			<h1 className="primary__heading">合作夥伴</h1>
			<ul className="partners__list">
				<li className="partners__item">
					<img
						className="partners__img"
						src={require("../../asset/img/brands/Fareastone_logo_l.png")}
						srcSet={`${require("../../asset/img/brands/Fareastone_logo_l.png")} 1x, ${require("../../asset/img/brands/Fareastone_logo_l@2x.png")} 2x`}
						alt="Banner 1"
					/>
					<span className="partners__name">遠傳電信</span>
				</li>
				<li className="partners__item">
					<img
						className="partners__img"
						src={require("../../asset/img/brands/Hanlin_logo_l.png")}
						srcSet={`${require("../../asset/img/brands/Hanlin_logo_l.png")} 1x, ${require("../../asset/img/brands/Hanlin_logo_l@2x.png")} 2x`}
						alt="Banner 1"
					/>
					<span className="partners__name">翰林出版社</span>
				</li>
				<li className="partners__item">
					<img
						className="partners__img"
						src={require("../../asset/img/brands/TC_Labor_logo_l.png")}
						srcSet={`${require("../../asset/img/brands/TC_Labor_logo_l.png")} 1x, ${require("../../asset/img/brands/TC_Labor_logo_l@2x.png")} 2x`}
						alt="Banner 1"
					/>
					<span className="partners__name">臺中市政府勞工局</span>
				</li>
				<li className="partners__item">
					<img
						className="partners__img"
						src={require("../../asset/img/brands/Gapuretea_logo_l.png")}
						srcSet={`${require("../../asset/img/brands/Gapuretea_logo_l.png")} 1x, ${require("../../asset/img/brands/Gapuretea_logo_l@2x.png")} 2x`}
						alt="Banner 1"
					/>
					<span className="partners__name">聚粹茶</span>
				</li>
				<li className="partners__item">
					<img
						className="partners__img"
						src={require("../../asset/img/brands/Aure_logo_l.png")}
						srcSet={`${require("../../asset/img/brands/Aure_logo_l.png")} 1x, ${require("../../asset/img/brands/Aure_logo_l@2x.png")} 2x`}
						alt="Banner 1"
					/>
					<span className="partners__name">奧爾資訊</span>
				</li>
				<li className="partners__item">
					<img
						className="partners__img"
						src={require("../../asset/img/brands/Tongtex_logo_l.png")}
						srcSet={`${require("../../asset/img/brands/Tongtex_logo_l.png")} 1x, ${require("../../asset/img/brands/Tongtex_logo_l@2x.png")} 2x`}
						alt="Banner 1"
					/>
					<span className="partners__name">東泰高中</span>
				</li>
			</ul>
		</section>
	);
};

export default PartnersApp;
