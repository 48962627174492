import React, { useEffect } from "react";
import NavibarApp from "./Navibar/NavibarApp";
import CarouselApp from "./Carousel/CarouselApp";
import FeatureApp from "./Feature/FeatureApp";
import { ReactComponent as Seperate } from "../asset/img/materials/Seperate_1200px.svg";
import UseCaseApp from "./UseCase/UseCaseApp";
import PartnersApp from "./Partners/PartnersApp";
import AboutUsApp from "./AboutUs/AboutUsApp";

const initWindowSize = () => {
	let vh = window.innerHeight;
	// console.log("vh", vh);
	let vw = window.innerWidth;
	// console.log("vw", vw);
	document.documentElement.style.setProperty("--vh", `${vh}px`);
	document.documentElement.style.setProperty("--vw", `${vw}px`);
};

function App() {
	useEffect(() => {
		initWindowSize();
		window.addEventListener("resize", initWindowSize);

		return () => {
			window.removeEventListener("resize", initWindowSize);
		};
	}, []);

	return (
		<div className="App">
			<div className="pretend" />
			<NavibarApp />
			<CarouselApp>
				<img
					className="carousel__img"
					src={require("../asset/img/banner/Banner_1@1x.png")}
					srcSet={`${require("../asset/img/banner/Banner_1@1x.png")} 1x, ${require("../asset/img/banner/Banner_1@2x.png")} 2x`}
					alt="Banner 1"
				/>
				<img
					className="carousel__img"
					src={`${require("../asset/img/banner/Banner_2@1x.png")}`}
					srcSet={`${require("../asset/img/banner/Banner_2@1x.png")} 1x, ${require("../asset/img/banner/Banner_2@2x.png")} 2x`}
					alt="Banner 2"
				/>
				<img
					className="carousel__img"
					src={`${require("../asset/img/banner/Banner_3@1x.png")}`}
					srcSet={`${require("../asset/img/banner/Banner_3@1x.png")} 1x, ${require("../asset/img/banner/Banner_3@2x.png")} 2x`}
					alt="Banner 3"
				/>
			</CarouselApp>
			<FeatureApp />
			<Seperate className="seperate" />
			<UseCaseApp />
			<Seperate className="seperate" />
			<PartnersApp />
			<Seperate className="seperate" />
			<AboutUsApp />
			<footer className="footer">
				Copyright &copy; RenHeng Tec. All rights reserved.
			</footer>
			<a href="/" className="aply-btn">
				申請試用
			</a>
		</div>
	);
}

export default App;
