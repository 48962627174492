import React, { useState, useEffect } from "react";
import { ReactComponent as BlockChainIcon } from "../../asset/img/icon/Blockchain.svg";
import { ReactComponent as CloudIcon } from "../../asset/img/icon/Cloud.svg";
import { ReactComponent as DataIcon } from "../../asset/img/icon/Data.svg";
import { ReactComponent as ECPlatformIcon } from "../../asset/img/icon/EC_platform.svg";
import { ReactComponent as PayIcon } from "../../asset/img/icon/Pay.svg";
import FeatureItemWrap from "./FeatureItemWrap";
import FeatureTriangle from "./FeatureTriangle";
import FeatureContent from "./FeatureContent";

const FeatureList = () => {
	const [activeFeature, setActiveFeature] = useState("");
	const [xCood, setXCood] = useState(0);
	const [yCood, setYCood] = useState(0);

	useEffect(() => {
		Object.keys(FEATURE_DATA).forEach((key, index) => {
			if (index === 0) {
				setActiveFeature(key);
			}
		});
	}, []);

	return (
		<div className="feature__list">
			<FeatureTriangle xCood={xCood} yCood={yCood} />
			<div className="feature__list-row feature__list-row--1">
				<FeatureItemWrap
					contentData={FEATURE_DATA}
					setActiveFeature={setActiveFeature}
					setXCood={setXCood}
					setYCood={setYCood}
					activeFeature={activeFeature}
					id="feature_data"
					className="feature__item feature__item--1"
				>
					<DataIcon className="feature__icon" />
					<span className="feature__name">雲運算</span>
				</FeatureItemWrap>
				<FeatureItemWrap
					contentData={FEATURE_DATA}
					setActiveFeature={setActiveFeature}
					setXCood={setXCood}
					setYCood={setYCood}
					activeFeature={activeFeature}
					id="feature_pay"
					className="feature__item feature__item--2"
				>
					<PayIcon className="feature__icon" />
					<span className="feature__name">雲支付</span>
				</FeatureItemWrap>
				<FeatureItemWrap
					contentData={FEATURE_DATA}
					setActiveFeature={setActiveFeature}
					setXCood={setXCood}
					setYCood={setYCood}
					activeFeature={activeFeature}
					id="feature_blockChain"
					className="feature__item feature__item--3"
				>
					<BlockChainIcon className="feature__icon" />
					<span className="feature__name">區塊鏈</span>
				</FeatureItemWrap>
				<FeatureContent
					activeFeature={activeFeature}
					contentData={FEATURE_DATA}
					row={1}
				/>
			</div>
			<div className="feature__list-row feature__list-row--2">
				<FeatureItemWrap
					contentData={FEATURE_DATA}
					setActiveFeature={setActiveFeature}
					setXCood={setXCood}
					setYCood={setYCood}
					activeFeature={activeFeature}
					offset={50}
					id="feature_cloud"
					className="feature__item feature__item--4"
					Yoffset={214}
				>
					<CloudIcon className="feature__icon" />
					<span className="feature__name">雲端應用整合</span>
				</FeatureItemWrap>
				<FeatureItemWrap
					contentData={FEATURE_DATA}
					setActiveFeature={setActiveFeature}
					setXCood={setXCood}
					setYCood={setYCood}
					activeFeature={activeFeature}
					offset={-50}
					id="feature_ecplatform"
					className="feature__item feature__item--5"
					Yoffset={214}
				>
					<ECPlatformIcon className="feature__icon" />
					<span className="feature__name">電商模組</span>
				</FeatureItemWrap>
				<FeatureContent
					activeFeature={activeFeature}
					contentData={FEATURE_DATA}
					row={2}
				/>
			</div>
		</div>
	);
};

export default FeatureList;

const FEATURE_DATA = {
	feature_data: {
		row: 1,
		content: ["保證機率", "遊戲轉蛋", "Slot Machine（角子機）\n遊戲機率"],
	},
	feature_pay: {
		row: 1,
		content: ["購物車", "電子發票/第三方金流串接", "電商物流"],
	},
	feature_blockChain: {
		row: 1,
		content: [
			"溯源資料鏈上儲存",
			"多種數位貨幣之入 / 出幣\n功能",
			"智能合約發幣",
		],
	},
	feature_cloud: {
		row: 2,
		content: [
			"雲端登入系統，支持\nEmail，電話註冊",
			"雲端訊息服務，兼顧資訊\n安全與穩固服務",
		],
	},
	feature_ecplatform: {
		row: 2,
		content: ["電商框架", "購物清單功能", "瀏覽清單"],
	},
};
