import React from "react";
import "./Feature.scss";
import FeatureList from "./FeatureList";

const FeatureApp = () => {
	return (
		<section id="feature" className="feature">
			<h2 className="primary__heading">探索我們的產品</h2>
			<FeatureList />
		</section>
	);
};

export default FeatureApp;
