import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";

const FeatureItemWrap = ({
  children,
  id,
  className,
  activeFeature,
  setXCood,
  setYCood,
  setActiveFeature,
  Yoffset,
  offset,
  contentData,
}) => {
  const el = useRef(null);
  const [isMobile, setIsMobile] = useState(isMob);

  useEffect(() => {
    function resetMobileMode() {
      setIsMobile(window.innerWidth <= 992);
    }

    window.addEventListener("resize", resetMobileMode);

    return () => window.removeEventListener("resize", resetMobileMode);
  }, []);

  useEffect(() => {
    el.current.focus();

    if (isMobile) return;

    const { x, y } = coodinateCalculator(el, offset, Yoffset);

    if (id === activeFeature) {
      setXCood(x);
      setYCood(y);
    }
    //eslint-disable-next-line
  }, [activeFeature, isMobile]);

  return (
    <div
      onClick={() => setActiveFeature(id)}
      ref={el}
      id={id}
      className={`${className} ${id === activeFeature ? "active" : ""}`}
      style={{
        transform: `translateX(${isMobile ? 0 : offset}%)`,
        overflow: isMobile && "hidden",
      }}
    >
      {children}

      {isMobile && (
        <ul
          className={`feature__content--mob__list ${
            id === activeFeature ? "active" : ""
          }`}
        >
          {contentData[id].content.map((content, index) => {
            return (
              <li key={index} className="feature__content--mob__item">
                {content}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

FeatureItemWrap.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  activeFeature: PropTypes.string,
  setXCood: PropTypes.func,
  setYCood: PropTypes.func,
  setActiveFeature: PropTypes.func,
  offset: PropTypes.number,
  contentData: PropTypes.objectOf(PropTypes.object),
};

export default FeatureItemWrap;

const isMob = window.innerWidth <= 992;

const coodinateCalculator = (el, offset, Yoffset) => {
  const x = offset
    ? el.current.offsetLeft +
      el.current.offsetWidth / 2 +
      (el.current.offsetWidth * offset) / 100
    : el.current.offsetLeft + el.current.offsetWidth / 2;
  const y = Yoffset
    ? Yoffset + el.current.offsetHeight
    : el.current.parentNode.offsetTop + el.current.offsetHeight;

  return { x, y };
};
