import createDataContext from "./createDataContext";

const actionTypes = {
  INIT_CHILDREN_GROUPS: "INIT_CHILDREN_GROUPS",
  INIT_WIDTH: "INIT_WIDTH",
  ON_NEXT_CLICK: "ON_NEXT_CLICK",
  ON_PREV_CLICK: "ON_PREV_CLICK",
  SET_CURRENT_INDEX: "SET_CURRENT_INDEX",
  SET_ANIMATED: "SET_ANIMATED",
  SET_WINDOW_WIDTH: "SET_WINDOW_WIDTH",
};

const initialState = {
  width: 0,
  windowWidth: 0,
  height: 0,
  childrenGroups: [],
  isAnimated: false,
  action: 0,
  currentIndex: 0,
};

const reducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.INIT_CHILDREN_GROUPS:
      return { ...state, childrenGroups: payload };
    case actionTypes.INIT_WIDTH:
      return { ...state, ...payload };
    case actionTypes.ON_NEXT_CLICK:
      return {
        ...state,
        currentIndex: state.currentIndex + 1,
        isAnimated: true,
      };
    case actionTypes.ON_PREV_CLICK:
      return {
        ...state,
        currentIndex: state.currentIndex - 1,
        isAnimated: true,
      };
    case actionTypes.SET_CURRENT_INDEX:
      return { ...state, currentIndex: payload };
    case actionTypes.SET_ANIMATED:
      return { ...state, isAnimated: payload };
    case actionTypes.SET_WINDOW_WIDTH:
      return { ...state, windowWidth: payload };
    default:
      return state;
  }
};

const initChildrenGroups = (children) => (dispatch) => {
  let childAry = [];
  const childrenLastIndex = children.length - 1;
  childAry.push(
    children[childrenLastIndex - 1],
    children[childrenLastIndex],
    ...children,
    children[0]
  );

  dispatch({ type: actionTypes.INIT_CHILDREN_GROUPS, payload: childAry });
};

const onImgLoad = (e) => (dispatch) => {
  const isMob = window.innerWidth <= 992;
  dispatch({
    type: actionTypes.INIT_WIDTH,
    payload: {
      width: isMob ? window.innerWidth * 0.925 : e.offsetWidth,
      height: e.offsetHeight,
      offset: e.offsetWidth / 2,
      windowWidth: window.innerWidth,
    },
  });
};

const onNextClick = () => (dispatch) => {
  dispatch({ type: actionTypes.ON_NEXT_CLICK });
};

const onPrevClick = () => (dispatch) => {
  dispatch({ type: actionTypes.ON_PREV_CLICK });
};

const setCurrentIndex = (index) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_CURRENT_INDEX,
    payload: index,
  });
};

const setAnimated = (animated) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_ANIMATED,
    payload: animated,
  });
};

const setWindowWidth = (width) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_WINDOW_WIDTH,
    payload: width,
  });
};

export const {
  Context: CarouselContext,
  Provider: CarouselProvider,
} = createDataContext(
  reducers,
  {
    initChildrenGroups,
    onImgLoad,
    onNextClick,
    onPrevClick,
    setCurrentIndex,
    setAnimated,
    setWindowWidth,
  },
  initialState
);
