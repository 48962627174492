import React from "react";
import "./Carousel.scss";
import PropTypes from "prop-types";
import { CarouselProvider } from "./context/CarouselContext";
import Carousel from "./Carousel";

const CarouselApp = ({ children }) => {
	return (
		<CarouselProvider>
			<Carousel>{children}</Carousel>
		</CarouselProvider>
	);
};

CarouselApp.propTypes = {
	children: PropTypes.node.isRequired,
};

export default CarouselApp;
