const { useEffect } = require("react");

const preventDefaultFunc = (e) => e.preventDefault();

export function usePreventScroll(preventScrollRef) {
	useEffect(() => {
		const preventScrolling = (e) => {
			if (preventScrollRef.current) {
				preventScrollRef.current.addEventListener(
					"touchmove",
					preventDefaultFunc
				);
			}
		};

		document.addEventListener("touchmove", preventScrolling, {
			passive: false,
		});
		return () => {
			document.removeEventListener("touchmove", preventScrolling);
		};
	}, [preventScrollRef]);
}
