import { useRef, useEffect } from 'react'

const useInterval = (delay, cb) => {
    const savedCallback = useRef(null);

    useEffect(() => {
        savedCallback.current = cb;
    }, [cb])

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }

        if(delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id)
        }
    }, [delay])
}

export default useInterval
