import React, { useState } from "react";
import "./AboutUs.scss";
import AboutUsButton from "./AboutUsButton";
import CoreValue from "./CoreValue";
import Teams from "./Teams";

const AboutUsApp = () => {
	const [selectButton, setSelectButton] = useState("");

	return (
		<section className="aboutus" id="aboutus">
			<h1 className="primary__heading">關於仁亨</h1>
			<AboutUsButton
				selectButton={selectButton}
				setSelectButton={setSelectButton}
			/>
			{selectButton === "core" && <CoreValue />}
			{selectButton === "teams" && <Teams />}
		</section>
	);
};

export default AboutUsApp;
