import React from "react";
import { ReactComponent as Design } from "../../asset/img/icon/Design_group_l.svg";
import { ReactComponent as Tech } from "../../asset/img/icon/Tec_group_l.svg";

const Teams = () => {
	return (
		<div className="teams">
			<ul className="teams__list">
				<li className="teams__item">
					<Tech className="teams__icon" />
					<div className="teams__desc">
						<h3 className="teams__desc--heading">技術團隊</h3>
						<p className="teams__desc--content">
							從底層系統基礎架構、前後端程式至各種系統介面和金流、遊戲 API
							串接，仁亨的技術團隊由技術長整合數位產業十餘年的豐富經驗，凝聚系統架構以及軟體工程師，提供完整的技術服務，並能滿足來自各國客戶端的客製化服務或是系統架構需求。
						</p>
					</div>
				</li>
				<li className="teams__item">
					<Design className="teams__icon" />
					<div className="teams__desc">
						<h3 className="teams__desc--heading">設計團隊</h3>
						<p className="teams__desc--content">
							來自台灣的新銳專業設計團隊，集結經營中台港澳以及東南亞的數位產品經驗，以及持續不斷進化的
							UX 和 UI 設計專研技術，打造出配合在地化設計的桌面／手機／App
							以及數位服務產品。
						</p>
					</div>
				</li>
			</ul>
		</div>
	);
};

export default Teams;
